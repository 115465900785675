







import { defineComponent, PropType } from '@vue/composition-api'
import OrderCard from '@/components/UpwardRunning/OrderHistory/OrderCard.vue'
import { RunningOrderInfo } from '@/GeneratedTypes/Running/ListInfo/RunningOrderInfo'

export default defineComponent({
  name: 'OrderGrid',

  props: { orders: { type: Array as PropType<RunningOrderInfo[]>, required: true } },
  components: { OrderCard },
  setup(props, ctx) {
    return {
      selected: function (o: RunningOrderInfo) {
        ctx.emit('select', o)
      },
    }
  },
})
