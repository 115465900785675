


























import { computed, defineComponent, PropType } from '@vue/composition-api'
import { RunningOrderInfo } from '@/GeneratedTypes/Running/ListInfo/RunningOrderInfo'
import { OrderTypesEnum } from '@/lib/support/models/GeneratedTypes/xOrders/xOrdersGeneral'
import dayjs from 'dayjs'

import OrderShipper from '@/components/UpwardRunning/OrderHistory/OrderShippers.vue'

export default defineComponent({
  name: 'OrderSummary',
  components: { OrderShipper },
  props: {
    order: { type: Object as PropType<RunningOrderInfo>, required: true },
  },
  setup(props) {
    const orderType = computed(() => {
      switch (props.order?.typeOrderID?.toLocaleLowerCase()) {
        case OrderTypesEnum.runningStartup:
          return 'Running Startup Order'
        case OrderTypesEnum.running:
          return 'Running Order'
        default:
          return props.order.typeOrderID
      }
    })
    const orderDate = computed(() => {
      return dayjs(props.order.processOnDate ?? new Date()).format('MMM D, YYYY')
    })

    const returnStatusFriendlyDescription = computed(() => {
      switch (props.order.upwardOrderStatusID!) {
        case 'NEW':
          return 'New'
        case 'RETURNS_SCM':
          return 'Under Review'
        case 'RETURNS_ACCOUNTING':
          return 'Under Review'
        case 'RETURNS_PENDING_ITEM_RETURN':
          return 'Waiting for items to be returned'
        case 'RETURNS_PS':
          return 'Review completed. You should hear from Partner Support soon'
        case 'COMPLETE':
          return 'Complete'
        default:
          return 'In Progress'
      }
    })

    return { orderType, orderDate, returnStatusFriendlyDescription }
  },
})
