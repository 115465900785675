









import { defineComponent, PropType } from '@vue/composition-api'
import { RunningOrderInfo } from '@/GeneratedTypes/Running/ListInfo/RunningOrderInfo'
import OrderSummary from '@/components/UpwardRunning/OrderHistory/OrderSummary.vue'
import SelectorCard from '@/components/SelectorCard.vue'

export default defineComponent({
  name: 'OrderCard',
  props: {
    order: { type: Object as PropType<RunningOrderInfo>, required: true },
  },
  components: { OrderSummary, SelectorCard },

  setup(props, ctx) {
    return {
      selected: function (o: RunningOrderInfo) {
        ctx.emit('select', o)
      },
    }
  },
})
